import { render, staticRenderFns } from "./ParcelList.vue?vue&type=template&id=701b1b0c&scoped=true&"
import script from "./ParcelList.vue?vue&type=script&lang=js&"
export * from "./ParcelList.vue?vue&type=script&lang=js&"
import style0 from "./ParcelList.vue?vue&type=style&index=0&id=701b1b0c&prod&scoped=true&lang=css&"
import style1 from "styles/components/queryHeader.css?vue&type=style&index=1&id=701b1b0c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "701b1b0c",
  null
  
)

export default component.exports