<template>
  <div class="content">
    <!-- <div class="menu">
      <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="selectChange">
        <el-menu-item index="0">全部</el-menu-item>
        <el-menu-item index="1">待支付</el-menu-item>
        <el-menu-item index="2">打单中</el-menu-item>
        <el-menu-item index="3">已出单</el-menu-item>
        <el-menu-item index="4">异常</el-menu-item>
        <el-menu-item index="5">取消</el-menu-item>
      </el-menu>
    </div> -->
    <div class="table">
      <el-table :data="orderData.data" style="width: 100%" :header-cell-style="{ background: '#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500' }" :row-style="{ height: '60px' }">
        <el-table-column label="序号" width="50" fixed>
          <template slot-scope="scope" ><span>{{ scope.$index + 1 }}</span></template >
        </el-table-column>
        <el-table-column label="平台" width="50">
          <template slot-scope="scope">

	  <img v-if="scope.row.platform === 0" src="~assets/icons/taobao.png" class="logImg"  />
	  <img v-if="scope.row.platform === 1" src="~assets/icons/jingdong.png" class="logImg"  />
	  <img v-if="scope.row.platform === 2" src="~assets/icons/ali.png" class="logImg"  />
	  <img v-if="scope.row.platform === 3" src="~assets/icons/pindd.png" class="logImg"  />
	  <img v-if="scope.row.platform === 4" src="~assets/icons/douyin.png" class="logImg"  />
	  <img v-if="scope.row.platform === 5" src="~assets/icons/kuaishou.png" class="logImg"  />
	  <img v-if="scope.row.platform === 6" src="~assets/icons/other.png" class="logImg"  />

          </template>
        </el-table-column>
        <el-table-column label="状态" width="62">
          <template slot-scope="scope">
            <!-- 待支付 -->
            <div v-if="scope.row.order_status === '1'" class="to-be-paid state">待支付</div>
            <!-- 打单中 -->
            <div v-if="scope.row.order_status === '2'" class="in-order state">打单中</div>
            <!-- 已出单 -->
            <div v-if="scope.row.order_status === '3'" class="order-issued state">已出单</div>
            <!-- 异常 -->
            <div v-if="scope.row.order_status === '4'" class="abnormal state">异常</div>
            <!-- 取消 -->
            <div v-if="scope.row.order_status === '5'" class="cancel state">已退款</div>
            <div v-if="scope.row.order_status === '6'" class="cancel state">发货完成</div>
          </template>
        </el-table-column>
        <el-table-column prop="third_order_no" label="三方单号" width="180"> </el-table-column>
        <el-table-column label="快递单号" width="200">
          <template slot-scope="scope">
	    <div v-if="scope.row.waybill_code === '' && scope.row.order_status === '5'">
	      <span class="pg_tips">请重新下单 </span>
	    </div>
	    <div v-else-if="scope.row.waybill_code === '***'">
	      ***<br>
	      <span class="nowaybill">加客服[商家认证]</span>
	    </div>
	    <div v-else>
              <span v-clipboard:copy="scope.row.waybill_code" v-clipboard:success="onCopy" v-clipboard:error="onError"  >{{ scope.row.waybill_code }} </span>
	    </div>
	    <div v-if="scope.row.third_waybill_code != null">
	      <span><span class="bufa">补发</span>{{ scope.row.third_waybill_code }} </span>
	    </div>
          </template>
	</el-table-column>
        <!-- <el-table-column prop="goods_name" label="商品名" width="140"> </el-table-column>
        <el-table-column label="商品图" width="80">
          <template slot-scope="scope">
            <div class="product-info">
              <img :src="scope.row.goods_images" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="总数量">
          <template slot-scope="scope">
            <div class="child-num">{{ scope.row.goods_num }} 件</div>
          </template>
        </el-table-column> -->
        <el-table-column prop="order_price" label="付款金额" width="80"> </el-table-column>
        <el-table-column label="收货人信息" width="320">
          <template slot-scope="scope">
            <div>{{ scope.row.receive_name }}
		{{ scope.row.receive_phone }}
	    </div>
            <span>{{ scope.row.receive_province + scope.row.receive_city + scope.row.receive_town + scope.row.receive_address }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="lp_reissue_sn" label="上家单号" width="160">
	</el-table-column> -->
        <el-table-column label="备注">
          <template slot-scope="scope">
	    <div class="pg_tips">{{ scope.row.package_tips }}</div>
	  </template>
	</el-table-column>
        <el-table-column label="创建时间" width="100">
          <template slot-scope="scope"
            ><span>{{ timeConversion(scope.row.createtime) }}</span></template
          >
        </el-table-column>
        <el-table-column prop="package_no" label="包裹号" width="210"> </el-table-column>
        <!-- <el-table-column prop="address" label="操作" width="200"> -->
        <!-- <template slot-scope="scope">
            <div class="handle">
              <div @click="payThis(scope.row)" v-if="scope.row.status === '1'">支付</div>
              <div @click="remove(scope.row.id)" v-if="scope.row.status === '1' || scope.row.status === '4'" class="remove">取消</div>
              <div v-if="scope.row.status !== '1'">
                <a :href="url + '/api/order/excel_order?id=' + scope.row.id">下载</a>
              </div>
              <div @click="eye(scope.row)">查看</div>
            </div>
          </template> -->
        <!-- </el-table-column> -->
      </el-table>
      <child-table :showDialog="showDialog" @closeDialog="closeDialog" :packageData="packageData" @updatePackageData="updatePackageData" :id="id" :showDownload="showDownload" />
      <div class="pagination">
        <el-pagination background @current-change="currentChange" :page-size="orderData.per_page" layout="prev, pager, next" :current-page="orderData.current_page" :total="orderData.total"> </el-pagination>
      </div>
    </div>
    <!-- 付款Dialog -->
    <pay-dialog :fromList="fromList" :dialogShow="dialogShow" @closePayDialog="closePayDialog" :name="orderItem.goods_name" :price="orderItem.order_price" :money="money" :orderId="orderItem.id" />
  </div>
</template>

<script>
import { getPackageList, getUserMoney } from 'network/api'
import { timeConversion } from 'utils/utils'
import ChildTable from './ChildTable'
import PayDialog from 'components/payDialog/PayDialog'

export default {
  components: { ChildTable, PayDialog },
  inject: ['mainReload'],
  data() {
    return {
      // activeIndex: '0',
      // orderData: {}, // 包含订单列表的对象
      showDialog: false,
      packageData: {}, // 包裹对象
      order_no: '',
      dialogShow: false,
      orderItem: {},
      money: '',
      id: null,
      showDownload: false,
      fromList: true,
      url: ''
    }
  },
  props: {
    orderData: Object // 包含订单列表的对象
    // eslint-disable-next-line vue/no-dupe-keys
    // activeIndex: String // 当前menu index
  },
  computed: {
    // 使用时间戳转化函数
    timeConversion() {
      return timeConversion
    }
  },
  created() {
    this.url = this.$url
  },
  methods: {
    // 根据分页改变获取数据
    currentChange(val) {
      this.$emit('curChange', val)
    },
    // menu激活回调
    selectChange(index) {
      this.$emit('menuChange', index)
    },
    // 查看子列表
    eye(item) {
      this.order_no = item.order_no
      this.id = item.id
      this.showDialog = true
      // 获取包裹列表
      getPackageList({ order_no: this.order_no }).then((res) => {
        this.packageData = res.data
        if (this.packageData.data.length !== 0) {
          this.showDownload = true
        } else {
          this.showDownload = false
        }
      })
    },
    closeDialog() {
      this.showDialog = false
    },
    // 子列表分页更新数据更新
    updatePackageData(val) {
      getPackageList({ order_no: this.order_no, page: val }).then((res) => {
        this.packageData = res.data
      })
    },
    // 取消
    remove(id) {
      this.$emit('remove', id)
    },
    download(data) {
      if (!data) {
        return
      }
      const BLOB = new Blob([data], { type: data.type })
      const url = window.URL.createObjectURL(BLOB)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', '分析文件.xlsx')
      document.body.appendChild(link)
      link.click()
    },
    // 支付
    payThis(data) {
      this.orderItem = data
      // 获取账户余额 并打开PayDialog
      getUserMoney().then((res) => {
        this.money = res.data.usermoney
        this.dialogShow = true
      })
    },
    onCopy(){
	this.$message('复制成功','success')
    },
    onError(){
	this.$message('复制失败','warning')
    },
    // 关闭payDialog
    closePayDialog() {
      this.dialogShow = false
      this.mainReload() // 更新列表
    }
  }
}
</script>

<style scoped src="styles/views/orderAdmin/orderTable.css">
</style>

<style>
.table .el-table tbody tr:hover > td {
  background-color: #e6f7ff;
}
.logImg{
  width:28px;
  height:auto;
}
</style>

<style scoped>

.cpImg{
   width:20px !important;
   height:auto;
   vertical-align:middle;
   margin:0 5px 0 5px;
}
.noway{
   text-align:center;
}
.nowaybill{
   border:1px solid #f7d66f;
   border-radius: 4px;
   background-color:#fcf5ed;
   padding: 1px 6px;
   color: #fb9f1c;
   line-height: 1.3;
   text-align:center;
}
.pg_tips{
  color:#ff2b30;
}
::v-deep .el-table .el-table__cell {
    padding: 2px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: left;
}
.bufa{
  padding:3px 5px;
  background-color:#6f93ff;
  color:#FFF;
  border-radius: 4px;
  margin-right:3px;
}
</style>